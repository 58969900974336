import axios from 'axios';

class Api {
    constructor() {
        this.client = axios.create({
            baseURL: `${process.env.REACT_APP_API_URL}/`,
        });
    }

    send(path = '', data, headers = null) {
        return this.client.post(path, data, { headers })
        .then(response => {
            if (!response) {
                return {status: 'error', msg: 'Une erreur est survenu lors de l\'envoie du formulaire, veuillez nous contacter par mail contact@zephyre.fr' };
            }

            if ( response.status !== 200 || response.data.status !== 'success') {
                const code = response.data.code;
                if (code === 'EMAIL_EXIST') {
                    return {status: 'error', msg: 'Vous semblez déjà avoir rempli le formulaire avec ce mail, veuillez nous contacter par mail contact@zephyre.fr si vous avez besoin de changer les données' };
                }
                else {
                    return {status: 'error', msg: 'Une erreur est survenu lors de l\'envoie du formulaire, veuillez nous contacter par mail contact@zephyre.fr avec le code suivant (' + code + ')' };
                }
            }

            return {status: 'success', msg: 'all good!', prospectId: response.data.prospect_id, redirectUrl: response.data.redirect_url};
        })
        .catch(error => {
            const code = error.response.data.code;
            if (code === 'EMAIL_EXIST') {
                return {status: 'error', msg: 'Vous semblez déjà avoir rempli le formulaire avec ce mail, veuillez nous contacter par mail contact@zephyre.fr si vous avez besoin de changer les données' };
            }
            else {
                return {status: 'error', msg: 'Une erreur est survenu lors de l\'envoie du formulaire, veuillez nous contacter par mail contact@zephyre.fr avec le code suivant (' + code + ')' };
            }
        })
    }

    get(path) {
        return this.client.get(path)
            .catch((err) => {
                const code = err?.response?.data?.code;
                if (code === 'INVALID_OPPORTUNITY_ID') {
                    window.location = '/404';
                } else {
                    return {status: 'error', msg: 'Une erreur est survenu lors de l\'envoie du formulaire, veuillez nous contacter par mail contact@zephyre.fr avec le code suivant (' + code + ')' };
                }
            })
    }
}

export default new Api();
