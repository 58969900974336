import React, { useContext } from 'react';
import { Link } from "react-router-dom";

import AppContext from '../../state/AppContext';
import { COPRO50_TYPE } from '../../utils/constants';
import workflows from '../../workflows/routes';

const Intro = () => {
    const {
        states: {
            showIntroContent,
            selectedCategory,
            showForm,
            disableWorkflowsLinks
        }
    } = useContext(AppContext);

    return (
        <div className={`contentHome${showIntroContent ? ' show' : ''}${showForm ? ' moveTop' : ''}`}>
            <h2 className="menu-section-title">Catégorie</h2>
            <ul className={`main-menu${selectedCategory === COPRO50_TYPE ? ' copro50-item' :''}`}>
                {(disableWorkflowsLinks 
                    ? workflows.filter(( { visible, route }) => visible && route === selectedCategory) 
                    : workflows.filter(({ visible }) => visible))
                        .map(({ route, label }) => (
                            <li key={route}>
                                <Link 
                                    id={route} 
                                    className={route === selectedCategory ? 'active' : ''} 
                                    to={`/${route}`}
                                >
                                    {label}
                                </Link>
                            </li>
                        )
                    )}
            </ul>
        </div>
    );
};

export default Intro;