import React from 'react';

const Footer = () => {

    return (
        <footer style={{textAlign: 'center'}}>
            <p>
                Si vous souhaitez nous écrire: 
                <a href="https://www.zephyre.fr/contact/"> contact</a>
            </p>
            <p>
                <a href="https://www.zephyre.fr/vie-privee/">vie privée</a>
            </p>
            <p>
                Pour toute interrogation concernant&nbsp;le traitement de ces informations par Zephyre, n’hésitez&nbsp;pas à contacter&nbsp;
                <a href="mailto:vieprivee@zephyre.fr">vieprivee@zephyre.fr</a>
                ,&nbsp;notre&nbsp;Délegué à la Protection des Données sera heureux de vous&nbsp;répondre
            </p>
        </footer>
    );
};

export default Footer;
