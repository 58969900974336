const states = {
    selectedCategory: null,
    showIntroContent: true,
    showForm: null,
    formBg: null,
    isSubmissionDone: false,
    disableWorkflowsLinks: false,
};

export default states;
