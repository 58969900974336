import React, { useContext } from 'react';
import AppContext from '../../state/AppContext';
import Header from '../Header';
import Intro from '../Intro';
import Outro from '../Outro';

const Home = ({ showThankyou }) => {
    const { states: { formBg }} = useContext(AppContext);
    const styles = { backgroundImage: `url(${formBg})` }

    return (
        <div id="home" className={`${formBg ? 'intro' : ''}`} style={formBg ? styles : {}}>
            <Header />
            <div className="main">
                {showThankyou ? <Outro /> : <Intro />}
            </div>
        </div>
    );
}

export default Home;