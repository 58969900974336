import React from 'react';
import MiniHeader from '../MiniHeader';

const NotFound = () => (
    <div>
        <MiniHeader />
        <h2 style={{ textAlign: 'center', margin: 16, fontSize: 20 }}>Nous avons rencontré un problème lors du chargement de votre formualaire. Veuillez nous contacter et nous fournir le lien de la page.</h2>
    </div>
);

export default NotFound;
