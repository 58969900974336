import React from 'react';

const Outro = () => (
    <div className="contentHome show" style={{ marginTop: 70}}>
        <h2 className="menu-section-title">Merci !</h2>
        <p></p>
        <p style={{ fontSize: 16}}>Nous vous recontacterons rapidement pour prendre rendez-vous avec vous pour finir les démarches</p>
        <button 
            onClick={() => { window.location.href = process.env.REACT_APP_ZEPHYRE_URL; }}
            style={{ marginTop: 35, fontSize: 22, padding: 15, backgroundColor: '#0073a5' }}
        >
            Retour sur le site zephyre.fr
        </button>
    </div>
);

export default Outro;
