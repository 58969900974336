import React from 'react';

import LogoImg from '../../static/img/logo.png';

const Logo = () => (
    <a href="https://www.zephyre.fr" className="bounce">
        <img src={LogoImg} alt="Logo" />
    </a>
);

export default Logo;