import React, { createContext } from 'react';
import useStates from './useStates';

const AppContext = createContext({});

export const AppContextWrapper = ({ children }) => {
    const [states, updateStates] = useStates();

    const handleRouteChanging = (selectedCategory, formBg) => updateStates(
        {
            ...states,
            showForm: true,
            selectedCategory,
            formBg,
        });
    
    const resetRoute = () => updateStates({ ...states, showForm: false, selectedCategory: null, formBg: null, disableWorkflowsLinks: false });

    const handleDisableWorkflowsLinks = () => updateStates({ ...states, disableWorkflowsLinks: true });

    return (
        <AppContext.Provider value={{ states, updateStates, handleRouteChanging, resetRoute, handleDisableWorkflowsLinks }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;
