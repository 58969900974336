import React, { useState, useEffect, useContext, useMemo } from 'react';

import Video from './Fields/Video';
import Form from './Form';
import AppContext from '../../../state/AppContext';
import { submitForm } from '../../../utils/submit';

import { 
    secondStepFormFields as fields,
    LOADING_BUTTON_TEXT, 
    DONE_BUTTON_TEXT
} from '../../../utils/constants';



const SecondStep = ({ video, workflowType, data }) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [submissionError, setSubmissionError] = useState(null);
    const [stepData, setStepData] = useState({});

    const { handleDisableWorkflowsLinks } = useContext(AppContext);

    const videoComponent = { Component: Video, name: 'video', src: video, noValue: true };

    if (fields.filter(f => f.name === videoComponent.name).length === 0) { 
        fields.push(videoComponent); 
    }

    const filteredFields = useMemo(
        () => fields.filter(({ hide }) => !hide || !hide(workflowType)),
        [workflowType]
    );

    const formSubmissionData = { 
        data: stepData, appendedData: data, step: 2,
        workflowType, setIsSending, setIsSubmitted, setSubmissionError,
        fields: filteredFields, 
    };

    useEffect(() => { 
        window.scrollTo({ top: 0, behavior: "smooth" }) 
        handleDisableWorkflowsLinks(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <Form 
            fields={filteredFields}
            data={stepData}
            setData={setStepData}
            submitForm={() => submitForm(formSubmissionData)} 
            isSubmitted={isSubmitted}
            disabled={isSending}
            secondButton={false}
            buttonLabel={isSending ? LOADING_BUTTON_TEXT : DONE_BUTTON_TEXT}
            submissionError={submissionError}
            onCloseModal={() => setSubmissionError(null)}
        />
    );
};

export default SecondStep;
