import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { openPopupWidget } from "react-calendly";
import ReactStars from "react-stars";

import YoutubeVideo from '../../YoutubeVideo'
import api from "../../../utils/api";
import Modal from "../_common/Modal";
import { Button } from "reactstrap";
import TextArea from "../_common/Fields/TextArea";
import Input from "../_common/Fields/Input";
import MiniHeader from "../../MiniHeader";

function SecondContact() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { opp_id } = useParams();

  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [calendlyIsOk, setcalendlyIsOk] = useState(false);

  const updateData = (newData) => {
    setData({ ...data, ...newData });
  }

  function isCalendlyEvent(e) {
    return e.data.event &&
      e.data.event === 'calendly.event_scheduled';
  };

  const handleDataChange = (event) => {
    updateData({
      video_comment: event.target.value
    });
  }

  const handleRatingChange = (rating) => {
    updateData({
      video_rating: rating
    })
  }

  const handleFilesAdding = (event) => {
    const files = data.files || [];
    for (let file of event.target.files) {
      files.push(file);
    }

    updateData({ files });
  }

  const handleFilesUploading = () => {
    setIsLoading(true);
    if (data.files && data.files.length > 0) {
      const filesData = new FormData();
      data.files.forEach((file) => {
        filesData.append('files[]', file)
      });

      api.send(`upload/${opp_id}`, filesData, { 'Content-Type': 'multipart/form-data' })
        .then((result) => {
          if (result.status === 'success') {
            updateData({ filesUploaded: true })
          } else {
            setError(result.msg);
          }
          setIsLoading(false)
        });
      }
  }

  const handleDataSaving = () => {
    setIsLoading(true);

    api.send(`prospect/${opp_id}/data`, { data })
      .then((result) => {
        if (result.status === 'success') {
          window.location = '/thanks';
        } else {
          setError(result.msg);
        }

        setIsLoading(false);
      })
    }

  const loadOpportunity = () => {
    api.get(`prospect/${opp_id}`)
      .then((result) => {
        if (result.status === 200 && result.data?.status === 'success') {
          updateData({
            email: result.data.email,
            name: result.data.name,
            type: result.data.type,
            youtubeId: result.data.youtube_id,
            calendlyUrl: result.data.calendly_url
          });
        }

        setIsLoading(false);
      })
  }

  const handleModalClose = () => {
    setError(null);
  }

  const handleOpenCalendly = () => {
    const prefill = {
      email: data.email,
      name: data.name
    };
    const url = data.calendlyUrl;
    openPopupWidget({ url, prefill });
  };

  useEffect(() => {
    loadOpportunity();
    window.addEventListener(
      'message',
      function(e) {
        if (isCalendlyEvent(e)) {
          console.log(e);
          setcalendlyIsOk(true)
        }
      }
    );
  }, []);

  useEffect(()=> {
    if(calendlyIsOk) {
      handleDataSaving();
    }
  }, [data, calendlyIsOk])

  // With opp_id you shoul call the API: /v2/prospect/XXXXX 
  // this API will tell you if ID is valid and will return those information
  // email, name, type (Copro, enterprise, individual, hotel, ...) the type will change 
  // the video and the calendly link
  // if you want to try an invalid opp_id you can use `aa` instead of `XXXXX`
 

  if (Object.keys(data).length === 0 && isLoading) {
    return (
      <div>
        <MiniHeader />
        <div className="main allFeildsForm show" style={{ paddingTop: 0 }}>
          <h3 style={{ textAlign: 'center', margin: 16 }}>Chargement...</h3>
        </div>
      </div>
    );
  }

  return (
    <>
      <MiniHeader />
      <div className="main-fluid">
          <div className="champForm video">
            <YoutubeVideo id={data.youtubeId || ''} />
          </div>
        <div className="main">
          <div className="champForm" style={{ clear: 'both', paddingBottom: 20 }}>
            <label style={{ minHeight: 'auto' }}>
              Avons-nous répondu à toutes vos questions ?
            </label>
            <ReactStars
              count={5}
              onChange={handleRatingChange}
              size={36}
              color2={'#0073a5'}
              half={false}
              value={data.video_rating || 0}
              className='rating-field'
            />
          </div>
          <TextArea
            rows={5}
            label="Besoin d'autres renseignements ?"
            placeholder="Posez vos questions ici"
            style={{ borderStyle: 'inset', borderWidth: 2, borderRadius: 2, padding: 4, width: '100%' }}
            name="video_comment"
            onChange={handleDataChange}
            value={data.video_comment}
            fullWidth
          />
          <div>
            <img src='data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 1" width="60" height="9"><circle fill="rgb(0, 115, 165)" cx="-1" cy="0.5" r="0.5"/> <circle fill="rgb(0, 115, 165)" cx="1" cy="0.5" r="0.5"/> <circle fill="rgb(0, 115, 165)" cx="3" cy="0.5" r="0.5"/></svg>' alt="" style={{display: 'block',marginLeft: 'auto', marginRight: 'auto', marginBottom: '50px'}}/>
            <Input
              type="file"
              label="Pouvez-vous nous transmettre votre dernière facture d'électricité : "
              onChange={handleFilesAdding}
              style={{ borderWidth: 2, borderRadius: 2 }}
              fullWidth
              multiple
            />
            <Button onClick={handleFilesUploading} disabled={!data.files || data.filesUploaded || isLoading} style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', marginBottom: '70px'}}>
              {isLoading ? 'Chargement...' :
                (data.filesUploaded ? 'Fichier correctement reçu' : 'Valider les fichers')
              }
            </Button>
          </div>
          <>
            <img src='data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 1" width="60" height="9"><circle fill="rgb(0, 115, 165)" cx="-1" cy="0.5" r="0.5"/> <circle fill="rgb(0, 115, 165)" cx="1" cy="0.5" r="0.5"/> <circle fill="rgb(0, 115, 165)" cx="3" cy="0.5" r="0.5"/></svg>' alt="" style={{display: 'block',marginLeft: 'auto', marginRight: 'auto', marginBottom: '50px'}}/>
            {!calendlyIsOk && (
              <div className="champForm" style={{ clear: 'both', paddingBottom: 20 }}>
                <label style={{display: 'block'}}>
                Prenez rendez-vous avec l’un de nos conseillers
                </label>
                <Button onClick={handleOpenCalendly} disabled={data.dataSaved || isLoading} style={{ marginLeft: 0 }}>
                  {isLoading ? 'Chargement...' : 'Prendre rendez-vous'}
                </Button>
              </div>
            )}
            {/* Next block to show only if file has been uploaded */}
            <br />
            {calendlyIsOk && (
              <div className="champForm" style={{ clear: 'both', paddingBottom: 20 }}>
                <label style={{display: 'block'}}>
                Prenez rendez-vous avec l’un de nos conseillers
                </label>
                <Button onClick={handleOpenCalendly} disabled={true}>
                  Votre rendez-vous est confirmé
                </Button>
                <br/>
                <div className="champForm" style={{marginTop: '40px',}}>
                  <Button onClick={handleDataSaving} disabled={data.dataSaved || isLoading} style={{ marginLeft: 0 }}>
                    {isLoading ? 'Chargement...' : 'Enregistrer'}
                  </Button>
                </div>
              </div>
            )}
          </>
        </div>
        <Modal
          title="Une erreur est survenue lors de l'envoie du formulaire" 
          content={error} 
          isOpen={Boolean(error)} 
          onClose={handleModalClose} 
        />
        <hr/>
      </div>
    </>
  );

  // To save the information you need to call API: /v2/prospect/<opp_id>/data
  // Need to provide data.video_rating, data.video_comment
}

export default SecondContact;
