import React from 'react';

const Label = ({ label }) => (
    <div className="champForm center">
        <h3 style={{ fontSize: 20 }}>
            {label}
        </h3>
    </div>
);

export default Label;