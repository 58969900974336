import api from './api';
import { validateSubmission } from './validation';

let prospectId;

export const submitForm = async ({ workflowType, step, fields, data, setIsSending, setIsSubmitted, setSubmissionError, nextStep, appendedData, phoneMeeting=false }) => {
    setIsSending(true);
    setIsSubmitted(true);
    
    const validatedData = validateSubmission(fields, data);

    if (validatedData) {
        const reqData = {
            ...validatedData,
            ...appendedData,
            step,
            want_phone_meeting: phoneMeeting,
        };

        if (prospectId && step === 2) {
            reqData.id = prospectId;
        }

        const result = await api.send('prospect', {
            prospect: {
                type: workflowType,
                ...reqData
            }
        });
        
        if (result && result.status === 'success') {
            if (step === 1) {
                if (nextStep) {
                    prospectId = result.prospectId;
                    nextStep();
                } else {
                    window.location.href = result.redirectUrl;
                }
                   
            } else {
                window.location.href = result.redirectUrl;
            }
        } else {
            setSubmissionError(result.msg)
        }
    } else {
        window.scrollTo({ top: 111, behavior: "smooth" });
    }
    
    setIsSending(false);
};
