import React, { useState } from 'react';

const Select = ({ label, onChange, name, pullRight, error, options, required = true }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    return (
        <div
            className={`champForm selectCheck${pullRight ? ' toRight' : ' toLeft'}${error ? ' error' : ''}`}
            style={{ width: '45%', paddingBottom: 25 }}
        >
        <label htmlFor={name}>{label}</label>
        <div 
            tabIndex="0" 
            name={name} 
            role="combobox"
            aria-controls="menu_options"
            aria-expanded={isOpen}
            className={`input-group input-group--append-icon input-group--text-field input-group--select input-group--single-line primary--text${isOpen ? ' input-group--open input-group--focused' : ''}`} 
            aria-required={required} aria-invalid={Boolean(error)} onClick={() => setIsOpen(!isOpen)}>
            <div className="input-group__input">
                <div className="input-group__selections" style={{ overflow: 'hidden' }}>
                    <div className="input-group__selections__comma">{selectedOption}</div>
                    <input name={name} disabled="disabled" tabIndex="-1" className="input-group--select__autocomplete" style={{ display: 'none' }} />
                </div>
                <div className="menu" style={{ display: 'inline-block' }}>
                    <div className={`menu__content menu__content--select${isOpen ? ' menuable__content__active menu-transition-enter-active' : ''}`} style={{ maxHeight: 300, width: '100%', top: 748.2, left: 20, transformOrigin: 'left top', zIndex: 8, display: isOpen ? 'block' : 'none' }} id="menu_options">
                        <div className="card" style={{ height: 'auto' }}>
                            <div className="list">
                            {options.map(option => (
                                <div key={option.value} onClick={() => { const e = { target: { name, value: option.value} }; onChange(e); setSelectedOption(option.label); } }>
                                    <div className="list__tile list__tile__content list__tile--link">
                                        <div className="list__tile__title">{option.label}</div>
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>
                </div>
                <i aria-hidden="true" className="icon material-icons input-group__append-icon input-group__icon-cb">arrow_drop_down</i>
            </div>
            <div className="input-group__details"></div>
            <span className="iconTriange"></span>
            <span className="msg-error" style={{ display: error ? 'block' : 'none', marginTop: 10 }}>{error}</span>
        </div>
    </div>
    );
};
export default Select;
