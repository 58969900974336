import React from 'react';
import { Input } from 'reactstrap';

const RadioBox = ({ name, label, onChange, options, pullRight, error, checkedOption, ...props }) => (
    <div className={`champForm${pullRight ? ' toRight' : ' toLeft'}${error ? ' error' : ''}`}>
        <div className='radio'>
        <label style={{ display: 'block', paddingBottom: 20 }}>{label}</label>
        {options.map((option, index) => (
            <label key={option.value}>
                <Input 
                    type="radio"
                    name={name} 
                    value={option.value} 
                    onChange={onChange}
                    defaultChecked={index === checkedOption}
                    {...props} 
                />
                <span className="radioSpan">{option.label}</span>
            </label>
        ))}
        <span className="msg-error" style={{ display: error ? 'block' : 'none'}}>{error}</span>
        </div>
    </div>
);

export default RadioBox;
