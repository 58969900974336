import React, { useContext, useEffect } from 'react';
import AppContext from '../../state/AppContext';
import Home from '../Home';

const Thankyou = () => {
    const { resetRoute } = useContext(AppContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {resetRoute(); }, []);

    return <Home showThankyou />;
};
export default Thankyou;