import Home from '../components/Home';
import Thankyou from '../components/Thankyou';
import SecondContact from '../components/Forms/SecondContact';
import NotFound from '../components/NotFound';

import {
    copro50Workflow, coproWorkflow, enterpriseWorkflow, individualWorkflow, SECOND_CONTACT
} from '../utils/constants';

const workflows = [
    coproWorkflow,
    individualWorkflow,
    copro50Workflow,
    enterpriseWorkflow,
    { route: SECOND_CONTACT, Component: SecondContact, visible: false },
    { route: "thanks", Component: Thankyou, visible: false },
    { route: "redirect-after-vyte",  Component: Thankyou, visible: false},
    { route: "404",  Component: NotFound, visible: false},
    { route: "", Component: Home, visible: false },
];

export default workflows;
