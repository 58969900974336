import React from 'react';

const Input = ({ type, label, name, onChange, pullRight, error, fullWidth, required = true, ...props }) => (
    <div
        className={`champForm${!fullWidth ? (pullRight ? ' toRight' : ' toLeft') : ''}${error ? ' error' : ''}`}
        style={{ paddingBottom: 10 }}
    >
        <label htmlFor={label} style={{ display: 'block'}}>
            {label}
        </label>
        <input type={type} name={name} id={name} aria-required={required} aria-invalid={Boolean(error)} onChange={onChange} {...props} />
        <span className="msg-error" style={{ display: error ? 'block' : 'none' }}>{error}</span>
    </div>
);

export default Input;
