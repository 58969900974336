import React, { useContext, useEffect, useState } from 'react';

import AppContext from '../../../state/AppContext';
import SecondStep from '../_common/SecondStep';
import FirstStep from './FirstStep';
import { COPRO50_TYPE, copro50Workflow } from '../../../utils/constants';
import coproVideo from '../../../static/video/copro.mp4';

const Copro50 = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [firstStepData, setFirstStepData] = useState({});

    const { handleRouteChanging } = useContext(AppContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { handleRouteChanging(copro50Workflow.route, copro50Workflow.bg); }, []);

    return(
        currentStep === 2 
            ? <SecondStep
                data={firstStepData} 
                workflowType={COPRO50_TYPE}
                video={coproVideo}
             />
            : <FirstStep 
                nextStep={() => setCurrentStep(2)} 
                data={firstStepData} 
                setData={setFirstStepData}
                workflowType={COPRO50_TYPE}
             />
    );
};

export default Copro50;
