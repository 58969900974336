import React from "react";
import { Button } from "reactstrap";

import Home from "../../Home";
import { isValidationPassed } from "../../../utils/validation";
import Modal from "./Modal";
import AlertMessage from "../../AlertMessage";

const Form = ({ isSubmitted, submitForm, buttonLabel, secondButton, htmlAlertMessage, textAlertMessage,
    alertMessageStatus, disabled, fields, data, setData, submissionError, onCloseModal }) => {

    const handleValueChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

    const renderField = ({ Component, errorMsg, extraValidation, noValue, hide, optional, ...field }) => (
        noValue 
            ? <Component key={field.name} {...field} /> 
            : <Component 
                key={field.name} 
                error={
                    isSubmitted 
                    && !isValidationPassed(data[field.name], extraValidation)
                    && errorMsg
                }
                onChange={handleValueChange} 
                {...field}
            />
    )

    return (
        <>
            <Home />
            <div className={`main allFeildsForm show`}>
                {(textAlertMessage || htmlAlertMessage) && (
                    <AlertMessage text={textAlertMessage} htmlText={htmlAlertMessage} status={alertMessageStatus} />
                )}
                <form>
                    {fields.map(renderField)}
                    <div className={`champForm${secondButton ? ' dualButtons' : ''}`}>
                        {secondButton && secondButton}
                        <Button onClick={() => submitForm(data)} disabled={disabled}>{buttonLabel || 'Continuer'}</Button>
                    </div>
                </form>
            </div>
            <Modal 
                title="Une erreur est survenue lors de l'envoie du formulaire" 
                content={submissionError} 
                isOpen={Boolean(submissionError)} 
                onClose={onCloseModal} 
            />
        </>
    );
}

export default Form;
