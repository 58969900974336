import React, { useState } from 'react';

import Input from '../_common/Fields/Input';
import Select from '../_common/Fields/Select';
import Form from '../_common/Form';

import { AUTRE, syndicCoproVals, referralVals, errorMsg, defaultFormFields, agreement} from '../../../utils/constants'
import { useLocation } from 'react-router-dom';
import { submitForm } from '../../../utils/submit';

const FirstStep = ({ nextStep, data, setData, workflowType }) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [submissionError, setSubmissionError] = useState(null);
    const urlQuery = new URLSearchParams(useLocation().search);
    const syndic = urlQuery.get("syndic");
    const referrer = urlQuery.get("referrer");

    const fields = [
        ...defaultFormFields,
    ];

    if (syndic && syndicCoproVals.map(s => s.value.toLowerCase()).includes(syndic.toLowerCase())) {
        data.syndic = syndic;
        data.referrer = `${syndic}-d`;
    } else if(referrer && referralVals.map(r => r.value.toLowerCase()).includes(referrer.toLowerCase())) {
        data.referrer = referrer;
        data.syndic = AUTRE;
        data.syndic_other = `${referrer}-d`;
    } else {
        fields.push(
            { Component: Select, label: 'Quel est votre syndicat ?', name: 'syndic', options: syndicCoproVals, errorMsg },
            { Component: Select, label: 'Comment avez-vous entendu parlé de nous ?', name: 'referrer', options: referralVals, pullRight: true, errorMsg }
        );
    }

    if (data.syndic === AUTRE) {
        fields.push({ Component: Input, type: 'text', label: "Préciser votre syndicat: ", name: 'syndic_other', maxLength: 40, errorMsg })
    }

    if (data.referrer === AUTRE) {
        fields.push({ Component: Input, type: 'text', label: "Préciser comment vous nous avez connu:", name: 'referrer_other', pullRight: true, maxLength: 40, errorMsg })
    }

    fields.push(agreement);

    const formSubmissionData = { 
        step: 1,
        data, nextStep, workflowType, fields, setIsSending, setIsSubmitted, setSubmissionError
    };

    return(
        <Form 
            fields={fields}
            data={data}
            setData={setData}
            submitForm={() => submitForm(formSubmissionData)} 
            isSubmitted={isSubmitted}
            disabled={isSending}
            submissionError={submissionError}
            onCloseModal={() => setSubmissionError(null)}
        />
    );
};

export default FirstStep;
