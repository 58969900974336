import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AppContext from '../../../state/AppContext';
import Input from '../_common/Fields/Input';
import Select from '../_common/Fields/Select';
import Form from '../_common/Form';

import { AUTRE, referralVals, defaultFormFields, errorMsg, agreement, INDIVIDUAL_TYPE, addressFormFields, LOADING_BUTTON_TEXT, DONE_BUTTON_TEXT, individualWorkflow} from '../../../utils/constants'
import { submitForm } from '../../../utils/submit';

const Individual = () => {
    const [data, setData] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [submissionError, setSubmissionError] = useState(null);
    const urlQuery = new URLSearchParams(useLocation().search);
    const referrer = urlQuery.get("referrer");

    const { handleRouteChanging } = useContext(AppContext);

    const fields = [
        ...defaultFormFields,
        ...addressFormFields,
    ];

    if(referrer && referralVals.map(r => r.value.toLowerCase()).includes(referrer.toLowerCase())) {
        data.referrer = referrer;
    } else {
        fields.push({ Component: Select, label: 'Comment avez-vous entendu parlé de nous ?', options: referralVals, name: 'referrer', pullRight: true, errorMsg });
    }

    if (data.referrer === AUTRE) {
        fields.push({ Component: Input, type: 'text', label: "Préciser comment vous nous avez connu:", name: 'referrer_other', pullRight: true, maxLength: 40, errorMsg })
    }

    fields.push(agreement);

    const formSubmissionData = { 
        workflowType: INDIVIDUAL_TYPE, step: 1,
        fields, data, setIsSending, setIsSubmitted, setSubmissionError
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { handleRouteChanging(individualWorkflow.route, individualWorkflow.bg); }, []);
    
    return(
        <Form 
            fields={fields}
            data={data}
            setData={setData}
            buttonLabel={isSending ? LOADING_BUTTON_TEXT : DONE_BUTTON_TEXT}
            submitForm={() => submitForm(formSubmissionData)} 
            isSubmitted={isSubmitted}
            disabled={isSending}
            secondButton={false}
            submissionError={submissionError}
            onCloseModal={() => setSubmissionError(null)}
        />
    );
};

export default Individual;
