import React from 'react';

const TextArea = ({ label, name, onChange, pullRight, error, fullWidth, placeholder, required = true, value, ...props }) => (
    <div className={`champForm${!fullWidth ? (pullRight ? ' toRight' : ' toLeft') : ''}${error ? ' error' : ''}`}>
        <label htmlFor={label}>
            {label}
        </label>
        <br />
        <textarea
          name={name}
          id={name}
          aria-required={required}
          aria-invalid={Boolean(error)}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          {...props}
        />
        <span className="msg-error" style={{ display: error ? 'block' : 'none' }}>{error}</span>
    </div>
);

export default TextArea;
