import React, { useContext, useEffect, useState } from 'react';

import AppContext from '../../../state/AppContext';
import SecondStep from '../_common/SecondStep';
import FirstStep from './FirstStep';
import { ENTERPRISE_TYPE, enterpriseWorkflow } from '../../../utils/constants';
import enterpriseVideo from '../../../static/video/enterprise.mp4';

const Enterprise = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [firstStepData, setFirstStepData] = useState({});

    const { handleRouteChanging } = useContext(AppContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { handleRouteChanging(enterpriseWorkflow.route, enterpriseWorkflow.bg); }, []);

    return(
        currentStep === 2 
            ? <SecondStep
                data={firstStepData} 
                workflowType={ENTERPRISE_TYPE}
                video={enterpriseVideo}
             />
            : <FirstStep 
                nextStep={() => setCurrentStep(2)} 
                data={firstStepData} 
                setData={setFirstStepData}
                workflowType={ENTERPRISE_TYPE}
             />
    );
};

export default Enterprise;
