import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { AppContextWrapper } from './state/AppContext';
import WorkflowsRouter from './workflows';
import Footer from './components/Footer';

const App = () => (
  <Router>
    <AppContextWrapper>
        <WorkflowsRouter />
    </AppContextWrapper>
    <Footer />
  </Router>
);

export default App;
