import React from 'react'

// status = primary/secondary/success/danger/warning/info/light/dark
const AlertMessage = ({ text, htmlText, status = 'primary' }) => {
  return (
    <div
      className={`alert alert-${status} ${text || htmlText ? 'alert-show' : 'alert-hidden'}`}
      role="alert"
      dangerouslySetInnerHTML={{ __html: htmlText }}
    >
      {text}
    </div>
  )
}

export default AlertMessage;
