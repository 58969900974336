import React, { useContext } from 'react';
import Logo from './Logo';
import AppContext from '../../state/AppContext';

const Header = () => {
    const { states: { showForm } } = useContext(AppContext);

    return (
        <header className={`header${showForm ? ' hide' : ''}`}>
            <div className={`logo${!showForm ? ' show' : ''}`}>
                <Logo />
            </div>
        </header>
    );
};

export default Header;