import React from 'react';
import { Switch, Route } from 'react-router-dom';

import routes from './routes';

const WorkflowsRouter = () => (
    <Switch>
        {routes.map(({ route, Component }) => (
            <Route path={`/${route}`} key={route} children={<Component />}/>
        ))}
    </Switch>
);

export default WorkflowsRouter;
