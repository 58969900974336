import React from 'react';
import { Button, Modal as ModalContainer, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const Modal = ({ title, content, isOpen, onClose }) => (
    <ModalContainer isOpen={isOpen} toggle={onClose} centered>
        {title && <ModalHeader toggle={onClose} style={{ fontWeight: 'bold'}}>{title}</ModalHeader>}
        <ModalBody>{content}</ModalBody>
        <ModalFooter>
            <Button onClick={onClose} style={{ backgroundColor: '#0073a5'}}>OK</Button>
        </ModalFooter>
    </ModalContainer>
);

export default Modal;