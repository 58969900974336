import React from 'react';
import Logo from '../Header/Logo';

const MiniHeader = () => (
  <>
    <div className="header-top"></div>
    <div className="second-contact-logo">
      <Logo />
    </div>
  </>
)

export default MiniHeader;