import React from "react";
import YouTube from 'react-youtube';

const OPTS = {
  height: '390',
  width: '640',
};

const YoutubeVideo = ({ id }) => (
  <YouTube videoId={id} opts={OPTS} />
)

export default YoutubeVideo;
