import React from 'react';
import { Input } from 'reactstrap';

const CheckBox = ({ label, name, onChange, pullRight, error, required = true, ...props }) => (
    <div className={`champForm check${pullRight ? ' toRight' : ' toLeft'}${error ? ' error' : ''}`}>
        <label htmlFor={name} style={{ marginTop: '30px' }}>
            <Input
                type="checkbox"
                id={name} 
                name={name} 
                aria-required={required}
                aria-invalid={Boolean(error)}
                onChange={(e) => { e.target.value = e.target.checked; onChange(e); }}
                {...props}
            />
            <span className="acceptSpan">{label}</span>
        </label>
        <span className="msg-error" style={{ display: error ? 'block' : 'none'}}>{error}</span>
    </div>
);

export default CheckBox;
