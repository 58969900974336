import React from 'react';

const Video = ({ src }) => (
    <div className="champForm center video">
        <video width="320" height="240" controls>
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    </div>
);

export default Video;