import { useState } from 'react';
import allStates from './allStates';

const useStates = () => {
    const [states, setStates] = useState(allStates);

    const updateStates = (state) => setStates({ ...states, ...state });

    return [ states, updateStates ];
};

export default useStates;
